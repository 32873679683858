import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FormCustom from '../components/Form'


const Contact = () => {
  return (

    <Row>
      <Col lg={8} md={8} sm={12}>
        <h1>Drop Me a Line.</h1>
<FormCustom />
      </Col>
    </Row>
)}

export default Contact